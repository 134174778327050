.countries-list {
  @extend %reset;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;

  &__country {
    @extend %layout__content-box;
    width: 100%;
    @include respond-to('>hands') {
      width: 48%;
      margin: 0;
      margin-top: 0;
    }

    @include respond-to('=lap') {
      &:nth-child(1n + 3) {
        margin-top: 2%;
      }
    }

    @include respond-to('>lap') {
      width: 32%;
      &:nth-child(1n + 4) {
        margin-top: 2%;
      }
    }
  }
  &__title {
    @extend %typography__header2;
    color: $color--accent-alt;
  }
}

.contacts-list {
  @extend %reset;
  &__contact {
    margin-top: 2rem;
  }
  &__title {
    @extend %typography__header3;
    color: $color--accent;
  }
  &__adress2 {
    margin-top: 0;
    margin-bottom: 0.75em;
  }
  &__phone,
  &__fax {
    @extend %reset;
    @extend %color__link;
    display: inline-block;
    white-space: nowrap;
    margin-right: 1em;
    &::before {
      content: '';
      mask: url(../assets/icons/phone.svg);
      -webkit-mask: url(../assets/icons/phone.svg);
      mask-size: cover;
      -webkit-mask-size: cover;
      background-color: currentColor;
      display: inline-block;
      height: 1em;
      width: 1em;
      vertical-align: text-top;
      margin-right: 0.25em;
    }
  }
  &__fax {
    &::before {
      mask: url(../assets/icons/printer.svg);
      -webkit-mask: url(../assets/icons/printer.svg);
    }
  }
}