.main-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__link {
        width: 100%;
        text-align: right;
        margin-bottom: $whitespace--sibling;
    }
    &__logo {
        height: 1rem;
        width: auto;
        @include respond-to('>hands') {
            display: none;
        }
    }
}
