@function scroll-indicator__gradient($use-gradient, $angle) {
  @if ($use-gradient) {
    @return linear-gradient($angle, rgba(0,0,0,0), rgba(0,0,0,0) 96%, rgba(0,0,0,0.15));
  }
  @return none;
}
@mixin scroll-indicator__gradients($top, $right, $bottom, $left) {
  $top-gradient: scroll-indicator__gradient($top, 0deg);
  $right-gradient: scroll-indicator__gradient($right, 90deg);
  $bottom-gradient: scroll-indicator__gradient($bottom, 180deg);
  $left-gradient: scroll-indicator__gradient($left, 270deg);

  background-image:
    $top-gradient,
    $right-gradient,
    $bottom-gradient,
    $left-gradient
  ;
}

[class*='scroll-indicator--'] {
  background-position: 0 0, 100% 0, 0 100%, 0 0;
  background-repeat: no-repeat;
}
.scroll-indicator--top {
  @include scroll-indicator__gradients(true, false, false, false);
}
.scroll-indicator--right {
  @include scroll-indicator__gradients(false, true, false, false);
}
.scroll-indicator--bottom {
  @include scroll-indicator__gradients(false, false, true, false);
}
.scroll-indicator--left {
  @include scroll-indicator__gradients(false, false, false, true);
}
.scroll-indicator--right.scroll-indicator--left {
  @include scroll-indicator__gradients(false, true, false, true);
}
.scroll-indicator--top.scroll-indicator--bottom {
  @include scroll-indicator__gradients(true, false, true, false);
}
.scroll-indicator--top.scroll-indicator--right {
  @include scroll-indicator__gradients(true, true, false, false);
}
.scroll-indicator--top.scroll-indicator--left {
  @include scroll-indicator__gradients(true, false, false, true);
}
.scroll-indicator--right.scroll-indicator--bottom {
  @include scroll-indicator__gradients(false, true, true, false);
}
.scroll-indicator--bottom.scroll-indicator--left {
  @include scroll-indicator__gradients(false, false, true, true);
}
.scroll-indicator--right.scroll-indicator--bottom.scroll-indicator--left {
  @include scroll-indicator__gradients(false, true, true, true);
}
.scroll-indicator--top.scroll-indicator--bottom.scroll-indicator--left {
  @include scroll-indicator__gradients(true, false, true, true);
}
.scroll-indicator--top.scroll-indicator--right.scroll-indicator--left {
  @include scroll-indicator__gradients(true, true, false, true);
}
.scroll-indicator--top.scroll-indicator--right.scroll-indicator--bottom {
  @include scroll-indicator__gradients(true, true, true, false);
}
.scroll-indicator--top.scroll-indicator--right.scroll-indicator--bottom.scroll-indicator--left {
  @include scroll-indicator__gradients(true, true, true, true);
}
