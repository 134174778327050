.image-overlay {
    position: fixed;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    &__wrapper {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        &--image {
            width: 100%;
            max-height: 90%;
        }

        svg {
            fill: white;
            cursor: pointer;
        }
    }
}
