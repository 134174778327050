.default-view,
.post-view {

    &__back {
        @extend %reset;
        @extend %typography__content;
        display: block;
        font-weight: 600;
        background: none;
        border: none;

        &::before {
            content: '<';
            display: inline-block;
            width: $whitespace--relation;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.post-view {

    &__back {
        color: white;
        text-shadow: 0 0 0.1em rgba(0,0,0,0.8);
        margin-top: $whitespace--relation;
        position: absolute;
    }
}

.default-view {

    &__back {
        color: $color--accent;
    }
}
