.team-overview {

  &__title {
    margin-top: 2rem;
    font-size: 1.375rem;
    font-weight: bold;
    color: $color--yellow;
  }

  &__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .team-member {
      width: 100%;
      margin-top: 2rem;
      text-align: center;

      @include respond-to('>hands') {
        width: 48%;
      }

      @include respond-to('>lap') {
        width: 32%;
      }

      &:last-of-type {
        margin-right: 0;
      }

      img {
        height: 300px;
        width: 100%;
        border-radius: 1rem;
        object-fit: cover;
      }

      p {
        margin: 0;
      }
    }
  }
}
