%sparse-event__cta {
  @extend %reset--formelement;
  @extend %whitespace__inline-block;
  @extend %color-like--block;
  @extend %block-with-svg-icon;
  width: 100%;
  justify-content: center;
  margin-top: 1em;
}

.events-list {
  &__post {
    @extend .posts-list__post;
  }
  &__media {
    @extend .posts-list__media;
  }
  &__title {
    @extend .posts-list__title;
    width: 100%;
    margin-bottom: 1em;
  }
  &__excerpt {
    @extend .posts-list__excerpt;
  }
  &__timestamp {
    @extend .posts-list__timestamp;
  }
  &__interested-count {
    @extend .posts-list__likes-count;
  }
  &__comment-count {
    @extend .posts-list__comment-count;
    &__placeholder {
      @extend .posts-list__comment-count__placeholder;
    }
  }
  &__interested-cta {
    @extend %sparse-event__cta;
    &--interested {
      @extend %sparse-event__cta;
      @extend %color-like--active-block;
    }
  }
}
