.simple-reply {
  flex-grow: 2;
  display: flex;
  &__text {
    @extend %reset--formelement;
    @extend %whitespace__inline-block;
    height: 2em;
    border: 1px solid $color--cta-contrast;
    flex-grow: 2;
  }
  &__cta {
    @extend %reset--formelement;
    @extend %whitespace__inline-block;
    color: $color--cta;
    background-color: $color--cta-contrast;
    * + & {
      margin-left: $whitespace--sibling;
    }
  }
}
