.events-schedule {
    &__list {
        @extend %reset;
    }
    &__month {
        * + & {
            margin-top: 2rem;
        }
    }
    &__title {
        margin-bottom: 1rem;
    }
}
