// The following classes don't have display flex...
// added to them by the WordPress block library...
// styles. Because of that, we add it here manually.
.is-content-justification-center,
.is-content-justification-right,
.is-content-justification-left,
.is-content-justification-space-between,
.is-vertical {
    display: flex;
}

.wp-block-button__link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
}
