
@font-face {
  font-family: 'ttnorms-Bold';
  font-style: bold;
  font-weight: 700;
  src: url('../fonts/ttnorms-Bold.woff') format('woff'),
}

@font-face {
  font-family: 'ttnorms';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ttnorms-Regular.woff') format('woff'),
}


$sans-serif-headings: 'ttnorms-Bold', sans-serif;
$sans-serif: 'ttnorms', sans-serif;

%typography__header1 {
  font-size: 1.83rem;
  line-height: 1.37;
  color: $color--accent;
  font-weight: bold;
  font-family: $sans-serif-headings;
}

%typography__header2 {
  font-size: 1.5rem; //18pt
  line-height: 1.22; //22pt
  font-weight: bold;
  font-family: $sans-serif-headings;
}

%typography__header3 {
  font-size: inherit;
  font-weight: bold;
  font-family: $sans-serif-headings;
}

%typography__content {
  font-size: 1.25rem;
  line-height: 1.4;
}

%typography__property {
  font-size: 1.08rem;
  line-height: 1.4;
}

h1, h2, h3, h4, h5, h6 {
  @extend %reset;
}
h3, h4, h5, h6, .has-large-font-size {
  @extend %typography__header3;
}

p {
  font-family: $sans-serif;
  margin: 0;
  font-size: inherit;
  color: $color--brand;
  * + & {
    margin-top: $whitespace--sibling;
  }
}

a {
  color: $color--yellow;
  &:hover {
    color: darken($color--yellow, 10%);
    text-decoration: none;
  }
}
