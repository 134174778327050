%radio-indicator {
  content: '';
  border: solid 2px $color--olive;
  border-radius: 20px;
  width: 16px;
  height: 16px;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
}

.radio-group {
  @extend %form-view__field;

  &__label {
    display: none;
  }
  &__choices, &__choice {
    @extend %reset;
  }
  &__choices {
    display: flex;
    flex-direction: column;
    margin-top: 0 - $whitespace--sibling;
    margin-left: 0 - $whitespace--sibling;
    @include respond-to('>=lap') {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  &__choice {
    flex-grow: 1;
    margin-top: $whitespace--sibling;
    margin-left: $whitespace--sibling;
  }
}

.radio-choice {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &__input {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    opacity: 0;
  }
  &__label {
    @extend %form-view__input;
    display: flex;
    justify-content: space-between;
    &::after {
      @extend %radio-indicator;
    }
    :checked + & {
      background-color: $color--cta-alt-contrast;
      &::after {
        background-color: $color--olive;
      }
    }
  }
}