@import 'FormView';
@import 'RadioGroup';

.form-factory {
  position: relative;
  max-width: #{map-get($breakpoints, hands)};
  margin: $whitespace--inner-block auto 0;

  input,
  textarea {
    margin-top: $whitespace--inner-block;
  }

  &__cta {
    @extend %reset--formelement;
    @extend %whitespace__inline-block;
    color: $color--cta;
    background-color: $color--cta-contrast;
    white-space: nowrap;
    margin-right: 1em;
    width: 100%;
    text-align: center;
    cursor: pointer;
    &::before {
      content: '';
      mask: url(../assets/icons/send--alt.svg);
      -webkit-mask: url(../assets/icons/send--alt.svg);
      mask-size: cover;
      -webkit-mask-size: cover;
      background-color: currentColor;
      display: inline-block;
      height: 1em;
      width: 1em;
      vertical-align: text-top;
      margin-right: 0.25em;
    }
  }

  &__in-progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    opacity: 0.35;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input--placeholder {
    @extend %form-view__input;
    @extend %form-view__field;
    @extend %placeholder;
    height: 2.5em;
  }

  &__group--placeholder {
    @extend %form-view__field;
    @extend .radio-group__choices;
    margin-top: 1.25em;
  }

  &__choice--placeholder {
    @extend .radio-group__choice;
  }

  &__text-area--placeholder {
    @extend %form-view__field;
    @extend .text-area__input--medium;
    @extend %placeholder;
  }
}

.form-factory--placeholder {
  @extend .form-factory;
}

.form-factory__confirmation {
  margin-bottom: 2rem;
}

.progress-ring {
  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

input[type="date"][value=""]:not(:focus),
input[type="time"][value=""]:not(:focus) {
  height: 40px;
  color: transparent;

  &:after {
    content: attr(placeholder);
    position: absolute;
    color: $color--gray;
  }
}
