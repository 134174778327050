%link-icon {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 0.5rem;
  font-size: 1rem;
}

%wp-content {
  h2 {
    @extend %typography__header2;
    * + & {
      margin-top: $whitespace--sibling * 3;
    }
  }

  h3 {
    * + & {
      margin-top: $whitespace--sibling * 3;
    }
  }

  ul {
    margin-bottom: $whitespace--sibling * 2;
    color: $color--brand;
    li {
      line-height: 1.6;
    }
  }

  a {
    color: $color--brand;
    text-decoration: underline;

    &[href^="tel:"] {
      &::before {
        @extend %link-icon;
        content: "\f879";
      }
    }
  }

  .wp-block-table {
    overflow: auto;
  }
  table {
    width: 100%;
    th {
      background: $color--olive;
      color: #fff;
      text-align: left;
    }
    td:first-of-type {
      background: $color--white;
    }
    th,
    td {
      padding: 0.35em 0.5em;
      min-width: 15rem;
    }
  }
}

.default-view {
  padding: $whitespace--inner-block 2rem 6 * $whitespace--inner-block;

  &__title {
    @extend %typography__header1;
    & + * {
      margin-top: $whitespace--relation;
    }
  }
  &__content {
    @extend %typography__content;
    @extend %content__image--container;

    .wp-block-button {
      margin-top: 1rem;

      &__link {
        @extend %reset;
        @extend %whitespace__inline-block;
        @extend %color-like--block;

        &:hover,
        &:focus {
          @extend %color-like--block;
        }
      }
    }

    .wp-block-media-text {
      margin: 1rem 0;
    }
  }
}

.default-view__content {
  @extend %wp-content;
}

.wp-block-buttons {

  .wp-block-button {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}
