$color--white: #fff;
$color--black: #000;
$color--olive: #D1D6CE;
$color--brand: #57656C;
$color--yellow: #C29F00;
$color--gray: #a9a9a9;

/**
 * Call to Action (CTA)
 **/

$color--cta: white;
$color--cta-contrast: $color--black;

$color--cta-alt: $color--brand;
$color--cta-alt-contrast: $color--olive;

%color-cta--block {
  color: $color--cta-alt;
  background-color: $color--cta-alt-contrast;
}

%color-cta--active-block {
  color: $color--cta;
  background-color: $color--cta-contrast;
}

/**
 * Content
 **/
$color--content: $color--black;
$color--content-contrast: white;

$color--content-alt: $color--brand;
$color--content-alt-contrast: $color--white;


$color--reduced: $color--olive;

$color--accent: $color--brand;
$color--accent-alt: $color--olive;

$color--link: $color--olive;
$color--link-active: $color--brand;

%color__link {
  color: $color--link;
  transition: color .3s ease;
  &:hover,
  &:focus {
    color: $color--link-active;
  }
}

/**
 * Like and Interest (like)
 **/

$color--like: $color--black;
$color--like-contrast: $color--olive;

$color--like-alt: $color--white;
$color--like-alt-contrast: $color--yellow;
%color-like--block {
  color: $color--like-alt;
  background-color: $color--like-alt-contrast;
}

%color-like--active-block {
  color: $color--like;
  background-color: $color--like-contrast;
}

.has-white-color {
  color: $color--white;
}

.has-black-color {
  color: $color--black;
}

.has-brand-olive-color {
  color: $color--olive;
}

.has-brand-primary-color {
  color: $color--brand;
}

.has-brand-yellow-color {
  color: $color--yellow;
}

.has-white-background-color {
  background-color: $color--white !important;
}

.has-black-background-color {
  background-color: $color--black !important;
}

.has-brand-olive-background-color {
  background-color: $color--olive !important;
}

.has-brand-primary-background-color {
  background-color: $color--brand !important;
}

.has-brand-yellow-background-color {
  background-color: $color--yellow !important;
}
