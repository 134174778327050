.replies-list {
  width: 100%;
  * + & {
    margin-top: $whitespace--relation;
  }

  &__name {
    @extend %typography__header3;

    & + * {
      margin-top: $whitespace--sibling;
    }
  }

  &__container {
    @extend %whitespace__inline-block;
    display: flex;
    flex-wrap: wrap;
    * + & {
      margin-top: $whitespace--relation;
      border-top: 1px solid $color--content-alt-contrast;
      border-radius: 0;
      padding-top: 2 * $whitespace--sibling;
    }
    .simple-reply {
      order: 4;
      width: 100%;
      margin-top: $whitespace--sibling;
    }
    .replies-list--sub {
      margin-top: $whitespace--relation;
      order: 5;
      width: 100%;
    }
  }

  &__comment {
    @extend %whitespace__inline-block;
    @extend %whitespace__block--breakout;
    margin-bottom: $whitespace--sibling;
    width: 100%;
    color: $color--content-alt;
    background-color: $color--content-alt-contrast;
  }

  &__timestamp {
    order: 3;
    color: $color--reduced;
  }

  &__action {
    flex-grow: 1;
    color: $color--reduced;
  }
}

.answer {
  margin-top: 1.5 * $whitespace--sibling;
  display: flex;
  

  &__name {
    font-weight: bold;
  }

  &__content {
    margin-left: $whitespace--sibling;
  }
}