%events-schedule__cta {
    @extend %reset--formelement;
    @extend %whitespace__inline-block;
    @extend %color-like--block;
    @extend %block-with-svg-icon;
    width: 100%;
    justify-content: center;
    margin-top: 1em;
  }
  
  .scheduled-event {
    &__post {
      @extend %reset;
      display: flex;
      flex-wrap: wrap;
      padding-right: 10rem;
      position: relative;
      * + & {
        margin-top: 2rem;
      }
    }
    &__media {
      border-radius: 1rem;
      display: block;
      background-color: black;
      background-size: cover;
      background-position: 50% 50%;
      width: 8rem;
      height: 100%;
      position: absolute;
      right: 0;
    }
    &__title {
      @extend .posts-list__timestamp;
      font-family: inherit;
      vertical-align: middle;
    }
    &__excerpt {
      @extend .posts-list__excerpt;
    }
    &__timestamp {
      @extend .posts-list__title;
      order: -1;
      width: 100%;
      margin-bottom: 1em;
      margin-top: 0;
      font-weight: bold;
      & > svg {
        display: none;
      }
    }
    &__interested-count {
      @extend .posts-list__likes-count;
    }
    &__comment-count {
      @extend .posts-list__comment-count;
      &__placeholder {
        @extend .posts-list__comment-count__placeholder;
      }
    }
    &__interested-cta {
      @extend %events-schedule__cta;
      &--interested {
        @extend %events-schedule__cta;
        @extend %color-like--active-block;
      }
    }
  }
  