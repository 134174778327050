%block-with-icon {
  &::before{
    content: ' ';
    display: inline-block;
    background-image: url('http:/kalkhoff-icons-grid.png');
    background-size: calc(6*2em) auto;
    background-position: 0.25em 0.45em;
    position: relative;
    top: -0.5em;
    width: 2em;
    height: 2em;
    margin: 0 0.25em -1em 0;
  }
}

%block-with-svg-icon {
  display: flex;
  align-items: center;
  & > svg {
    height: 1.5rem;
    fill: currentColor;
    vertical-align: middle;
    margin-right: 0.5em;
  }
}

%block-with-icon--eye {
  @extend %block-with-icon;
  &::before {
    background-position: -7.75em -1.65em;
  }
}

%block-with-icon--clock {
  @extend %block-with-icon;
  &::before {
    background-position: -5.75em -3.85em;
  }
}

%block-with-icon--comment {
  @extend %block-with-icon;
  &::before {
    background-position: -9.75em -1.65em;
  }
}

%block-with-icon--like {
  @extend %block-with-icon;
  &::before {
    background-position: -3.75em -1.75em;
  }
}