.category-labels {
  @extend %reset;
  width: 100%;
  display: flex;
}

.category-label {
  @extend %typography__property;
  padding: 0.35em 0.5em;
  border: 1px solid black;
  border-radius: 0.45em;
  margin-left: 0.5em;

  &:first-of-type {
    margin-left: 0;
  }

  &__events {
    border-color: $color--olive;
    color: $color--olive;
  }

  &__strategy {
    border-color: $color--brand;
    color: $color--brand;
  }
  a {
    text-decoration: none;
    color: $color--brand;
  }

  &.highlight {
    border-color: $color--yellow;
    background-color: $color--yellow;

    a {
      color: $color--white;
    }
  }
}
