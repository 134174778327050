%layout__content-box {
  padding: 2rem;
  border: 1px solid $color--olive;
  border-radius: 1.5rem;
  box-sizing: border-box;

  * + & {
    margin-top: 2rem;
  }
}

%content__image--container {
  figure{
    @extend %reset--figure-element;
    margin: 1em 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.is-style-button-center {
  display: flex !important;
  justify-content: center;
}

.aligncenter {
  text-align: center;
}
