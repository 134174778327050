.hr-view {

    &__posts {
        @extend .related-posts;
        padding-bottom: 0;
        margin-top: 4 * $whitespace--inner-block;
    }

    &__button-wrapper {
        margin-top: 2 * $whitespace--inner-block;
        text-align: center;

        a {
            @extend %reset;
            @extend %whitespace__inline-block;
            @extend %color-like--block;
            cursor: pointer;
            border: none;
            color: $color--white;

            &:hover,
            &:focus {
                @extend %color-like--block;
            }
        }
    }
}
