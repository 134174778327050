/*
# Reset
This extension is for resetting basic HTML styling on a selector basis.
Any object that needs resetting, simply let it `@extend` this fragment
and it'll be added to the list of objects to revert to base styling.
*/
%reset {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-decoration: none;
}

%reset--formelement {
  @extend %reset;
  border: 0 none;
  overflow:visible;
	width:auto;
	background:none;
  -webkit-appearance: none;
  &:focus {
    outline: 0;
  }
}

%reset--figure-element {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}