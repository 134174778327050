$whitespace--sibling: 0.75rem;
$whitespace--relation: 3 * $whitespace--sibling;

$whitespace--inner-block: 2rem;

%whitespace__inline-block {
  padding: $whitespace--sibling 2 * $whitespace--sibling;
  border-radius: 0.75 * $whitespace--sibling;
}

%whitespace__block--breakout {
  margin-left: 0 - (2 * $whitespace--sibling);
  margin-right: 0 - (2 * $whitespace--sibling);
}