.toggle {
    position: relative;
    width: 5rem;
    height: 2.6rem;

    input {
        width: 0;
        height: 0;
        opacity: 0;

        &:checked + span {
            background-color: $color--yellow;
        }

        &:checked + span::before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        &:focus + span {
            box-shadow: 0 0 5px $color--yellow;
        }
    }

    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color--gray;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 2rem;
        
        &::before {
            position: absolute;
            content: "";
            height: 2rem;
            width: 2rem;
            left: 4px;
            bottom: 4px;
            background-color: $color--white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }
    }
}
