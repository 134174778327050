%form-view__input {
  @extend %reset--formelement;
  @extend %whitespace__inline-block;
  border: 1px solid $color--cta-contrast;
  box-sizing: border-box;
  width: 100%;
}
%form-view__field {
  & + * {
    margin-top: $whitespace--inner-block;
  }
}

.gform_confirmation_message {
  color: $color--brand;
  margin-top: 3rem;
}

.text-field {
  @extend %form-view__field;

  &__label {
    display: none;
  }
  &__input--medium {
    @extend %form-view__input;
  }
}

.text-area {
  @extend %form-view__field;

  &__label {
    display: none;
  }
  &__input--medium {
    @extend %form-view__input;
    height: 10em;
  }
}

.fileupload-field {
  display: flex;
  justify-content: center;

  @include respond-to('>=lap') {
    display: inline-flex;
    width: 33%;
    vertical-align: top;
    margin-top: $whitespace--inner-block;
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed $color--black;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    height: 15rem;
    width: 15rem;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &__content {
    border-radius: 0.25rem;
    height: 15rem;
    margin-bottom: 1rem;
    object-fit: cover;
    width: 15rem;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
