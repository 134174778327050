.home-view {
  @extend .default-view;

  &__title-container {
    @extend .default-view__title;
  }

  &__filter-container {

    @include respond-to('<=hands') {
      display: flex;
      flex-direction: column;
    }
  }

  &__user-name {
    @extend %reset;
    color: $color--black;
    &:empty::before {
      @extend %placeholder;
      width: 6em;
    }
  }
}
