.navigation-view {
    width: 100%;
    border: 1px solid rgba(0,0,0 ,0.15);
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: $color--brand;
    @include respond-to('>=lap') {
        top: 0;
        bottom: auto;
        justify-content: space-between;
    }
    &__logo {
        display: none;
        @include respond-to('>=lap') {
            display: flex;
            max-height: 2rem;
            padding: 0 2rem;
            width: auto;
        }
    }
    &__container {
        @extend %reset;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 0.25 * $whitespace--inner-block;
        padding-bottom: $whitespace--inner-block;
        user-select: none;
        max-width: #{map-get($breakpoints, lap)};
        margin: 0 auto;
        @include respond-to('>=lap') {
            justify-content: space-between;
            padding-bottom: 0.25 * $whitespace--inner-block;
        }
    }
    &__list {
        @extend %reset;
        display: flex;
        justify-content: space-around;
        width: 100%;
        @include respond-to('>=lap') {
            width: auto;
        }
    }
    &__item {
        all: unset;
        user-select: none;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 0%;
        align-items: center;
        color: $color--white;
        text-decoration: none;
        text-align: center;
        padding: 0.5 * $whitespace--inner-block;
        font-size: 12px;
        white-space: nowrap;
        @include respond-to('>=lap') {
            flex-direction: row;
            padding-left: $whitespace--inner-block;
            padding-right: $whitespace--inner-block;
            font-size: 1rem;
            justify-content: center;
        }
        &.active,
        &:hover,
        &:focus {
            color: $color--yellow;
            svg {
                fill: $color--yellow;
            }
        }

        &:hover {
            cursor: pointer;
        }

        svg {
            fill: $color--white;
            width: 2rem;
            height: 2rem;
            padding-bottom: 0.25 * $whitespace--inner-block;
            @include respond-to('>=lap') {
                padding-bottom: 0;
                margin-right: 0.25 * $whitespace--inner-block;
            }
        }
    }
}

.more-items-navigation {
    width: 100vw;
    height: 90vh;
    background: $color--brand;
    position: fixed;
    z-index: 1;
    left: 0;

    @include respond-to('>=lap') {
        height: 100vh;
    }

    &__list {
        margin-top: 2rem;
        margin-bottom: 2rem;
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include respond-to('>=lap') {
            position: relative;
        }

        &--item {
            color: $color--white;
            text-decoration: none;
            font-size: 2rem;
        }
    }
}
