#iOSPWA-overlay {
    background-color: rgba(0, 0, 0, .8);
    left: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity .2s ease-in;
    width: 100vw;
    z-index: 999999;
	pointer-events: none;
	touch-action: none;
	display: none;
	&.visible {
		opacity: 1;
		display: block;
	}
}

#iOSPWA-container {
    backdrop-filter: blur(10px);
	background:hsla(0, 0%, 98%, .8);
    border-radius: 10px;
    bottom: 0;
    color: #000;
    filter: brightness(1.1);
    left: 0;
    margin: 0 8px 10px;
    overflow: hidden;
    position: fixed;
    transform: translateY(calc(100% + 10px));
    transition: transform .4s cubic-bezier(.4, .24, .3, 1);
    width: calc(100vw - 16px);
    z-index: 999999;
	&.visible {
		transform: translateY(0);
		display: block;
	}
	&.hidden {
		pointer-events: none;
		touch-action: none;
	}
}

@media (prefers-color-scheme: dark) {
    #iOSPWA-container {
        background: rgba(120, 120, 128, .32);
    }
}

.iOSPWA-header {
    align-items: center;
    border: 0;
    border-bottom: .5px solid rgba(0, 0, 0, .1);
    border-right-width: .5px;
    border-top-width: .5px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 13px 16px;
    border-color:rgba(60, 60, 67, .29);
	&-title {
		color:#000;
		font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
		font-size: 18px;
		font-weight: 500;
		line-height: 1.125;
		margin: 0;
		padding: 0;
	}
	&-cancel {
		color:#007aff;
		font-size: 16px;
		padding: 0;
		margin: 0;
		border: 0;
		background: transparent;
	}
}

.iOSPWA-body {
    display: flex;
    width: 100%;
	&-description {
		border: 0;
		border-bottom: .5px solid rgba(0, 0, 0, .1);
		border-right-width: .5px;
		border-top-width: .5px;
		color: inherit;
		margin: 0 16px;
		padding: 16px;
		width: 100%;
		border-color:rgba(60, 60, 67, .29);
	}
}

.iOSPWA-body-text {
    color:rgba(60, 60, 67, .6);
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 13px;
    line-height: 17px;
    margin: 0;
    padding: 0;
    font-weight:600;
}

.iOSPWA-steps {
    color: inherit;
    margin: 0 16px;
    padding: 16px;
	&__step {
		align-items: center;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		text-align: left;
		margin-bottom: 16px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.iOSPWA-icon {
	flex: 0 0 auto;
	height: 30px;
	margin-right: 32px;
	width: 25px;
	&__home {
		color: #58595b;
		fill: #58595b;
	}
	&__share {
		color: #007aff;
		fill: #007aff;
	}
}

@media (prefers-color-scheme: dark) {
	#iOSPWA-overlay {
        background: rgba(0, 0, 0, .6);
        color: rgba(235, 235, 245, .6)
    }
    .iOSPWA-header {
        border-color: rgba(84, 84, 88, .6);
		&-title {
			color: #fff;
		}
		&-cancel {
			color: #0984ff;
		}
    }
    .iOSPWA-body {
		&-description {
			border-color: rgba(84, 84, 88, .6);
		}
	}
	.iOSPWA-body-text {
        border-color: rgba(235, 235, 245, .6);
        color: rgba(235, 235, 245, .6);
    }
	.iOSPWA-icon {
		&__home {
			color: #fff;
			fill: #fff;
		}
		&__share {
			color: #0984ff;
			fill: #007aff;
		}
	}
}