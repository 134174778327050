@use "sass:map";

$breakpoints: (
  'palm': 320px,
  'narrow': 485px,
  'hands': 768px, 
  'lap': 1024px,
  'desk': 1440px,
) !default;

$media-queries: ();

@mixin generateMediaQueries() {
  $previousKey: false;
  $previousValue: false;

  @each $map-key, $map-value in $breakpoints {
    $local-queries: (
      '<=#{$map-key}': ( max-width:  #{$map-value} )
    );
    $local-queries: map-merge($local-queries, (
      '>#{$map-key}': ( min-width:  #{$map-value}  )
    ));
    @if $previousValue != false {
      $local-queries: map-merge($local-queries, (
        '>=#{$map-key}': ( min-width:  #{$previousValue}  )
      ));
      $local-queries: map-merge($local-queries, (
        '=#{$map-key}': ( min-width:  #{$previousValue} ) unquote("and") ( max-width:  #{$map-value} )
      ));
      $local-queries: map-merge($local-queries, (
        '!#{$map-key}': ( max-width:  #{$previousValue} ) unquote("and") ( min-width:  #{$map-value} )
      ));
    }

    $media-queries: map-merge($media-queries, $local-queries) !global;
    $previousKey: $map-key;
    $previousValue: $map-value;
  }
}

@include generateMediaQueries();
@debug $media-queries;

// Breakpoints script based on
// https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel, Martin Reurings
/// @param {String} $breakpoint - Breakpoint name
/// @param {String} $modifier - Breakpoint modifier [up|down|only]
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($media-queries, $breakpoint) {
    // Prints a media query based on the value
    @media #{map-get($media-queries, $breakpoint)} {
      @content;
    }
  }
  
  // If the key doesn't exist in the map
  @else {
    @error "Unfortunately, no value could be retrieved for `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($media-queries)}.";
  }
}