.posts-search {
    @extend %whitespace__inline-block;
    border: 1px solid $color--cta-contrast;
    box-sizing: border-box;

    @include respond-to('<=hands') {
        margin-top: $whitespace--sibling;
    }

    @include respond-to('>hands') {
        margin-left: $whitespace--sibling;
    }
}
