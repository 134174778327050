.account-view {
  @extend .default-view;

  &__title-container {
    @extend .home-view__title-container;

    .main-title__link {
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__description {
    white-space: pre-line;
  }

  &__user-name {
    @extend .home-view__user-name;
  }

  &__topic {
    @extend %typography__header2;
  }

  :disabled + &__label::after {
    @extend %placeholder--wait;
  }

  .form-factory {
    margin-left: 0;
    margin-bottom: $whitespace--inner-block;
  }

  &__notifications {
    display: flex;
    align-items: center;

    .account-view__label {
      margin-left: 2rem;
    }
  }

  &__phone {
    margin-top: $whitespace--relation;

    &--number {
      @extend %form-view__input;

      &.auto-width {
        width: auto;
      }
    }

    &--submit {
      @extend %reset--formelement;
      @extend .post-view__star;
      margin-top: $whitespace--relation;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__language {
    @extend %select;
    margin-bottom: $whitespace--relation;;
  }
}
