.in-progress {
  display: flex;
  align-items: center;
  justify-content: center;

  &__ring {
    animation: rotation 2s infinite linear;
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }  
  }
}