.post-view {
  @extend %reset;
  @extend %typography__content;
  margin: 0 $whitespace--inner-block;
  padding-bottom: 2 * $whitespace--inner-block;
  display: flex;
  flex-wrap: wrap;
  @include respond-to('>lap') {
    max-width: #{map-get($breakpoints, lap)};
    margin: 0 8 * $whitespace--inner-block;
  }

  &__media {
    background-color: black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: -$whitespace--inner-block;
    margin-right: -$whitespace--inner-block;
    height: $whitespace--inner-block;
    width: calc(100% + #{2 * $whitespace--inner-block});
    padding-top: 48%;
    @include respond-to('>lap') {
      margin-left: -8 * $whitespace--inner-block;
      margin-right: -8 * $whitespace--inner-block;
      margin-bottom: -2 * $whitespace--inner-block;
      width: calc(100% + #{16 * $whitespace--inner-block});
      border-radius:  0.75 * $whitespace--inner-block;
    }
    &::after {
      $postview--radius: 0.75 * $whitespace--inner-block;
      content: ' ';
      display: block;
      background-color: white;
      height: $whitespace--inner-block;
      border-radius: $postview--radius $postview--radius 0 0;
      @include respond-to('>lap') {
        height: 4 * $whitespace--inner-block;
        margin: -3 * $whitespace--inner-block 4 * $whitespace--inner-block 0;
      }
    }
  }

  &__timestamp {
    @extend %typography__property;
    color: $color--reduced;
    * + & {
      margin-top: $whitespace--sibling;
    }
  }

  &__title {
    @extend %typography__header2;
    width: 100%;
    * + & {
      margin-top: $whitespace--relation;
    }
  }

  &__content {
    width: 100%;
    margin-bottom: $whitespace--relation;

    & h2 {
      @extend %typography__header2;
      * + & {
        margin-top: $whitespace--relation;
      }
    }

    & h3,
    & h4 {
      * + & {
        margin-top: $whitespace--relation / 2;
      }
    }

    & p {
      * + & {
        margin-top: $whitespace--sibling;
      }
    }
    & img {
      max-width: 100%;
      height: auto;
    }

    figure {
      margin: 1em 0;
    }
  }

  &__star {
    @extend %reset;
    @extend %whitespace__inline-block;
    @extend %color-like--block;
    @extend %block-with-svg-icon;
    justify-content: center;
    max-height: 4em;
    @include respond-to('<=narrow') {
      width: 100%;
    }

    &--liked {
      @extend .post-view__star;
      @extend %color-like--active-block;
    }

    & + .simple-reply {
      margin-left: $whitespace--sibling;
      @include respond-to('<=narrow') {
        margin-left: 0;
        margin-top: $whitespace--sibling;
        width: 100%;
      }
    }
  }
}
